import { get, post } from "./Api";

export function SearchSubTreatmentByBranch({ params }) {
  return get({ url: `/api/v1/healthland/slot/subTreatmentByBranch`, params });
}
export function CreateImportSlot({ data }) {
  return post({ url: `/api/v1/healthland/slot/importSlot`, data ,timeout: 60000 });
}
export function SearchBranchByTreatmentNo({ params }) {
  return get({ url: `/api/v1/healthland/slot/branchByTreatmentNo`, params });
}