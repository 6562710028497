  const dev = {
    BASE_URL: "https://axelor-api-dev-healthland.scapp.work",
  };
  
  const uat = {
    BASE_URL: "https://reservation-uat-api.healthlandspa.com",
  };
  
  const prod = {
    BASE_URL: "https://reservation-prod-api.healthlandspa.com",
  };
  
  
  const config = () => {
    //   if (process.env.env_type === "development") {
    //     console.log('config dev!')
    //     return dev;
    //   } else if (process.env.env_type === "uat") {
    //     console.log('config uat!')
    //     return uat;
    //   } else if (process.env.env_type === "production") {
    //     console.log('config prod!')
    //     return prod;
    //   }
      return uat;
  }
  
  export default {
    config
  }
  
  