import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import _ from 'lodash'
import { ExportBookingDetail } from "../../services/Export";
import { Button } from 'primereact/button';
import helper from 'services/helper';

let defRow = [
  { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
  { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
  { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
  { v: "line\nbreak", t: "s", s: { alignment: { horizontal: 'center', vertical: 'center', wrapText: true } } },
];

let mockRes = [
  // {
  //   id: 14,
  //   bookingNo: 14,
  //   bookingDate: "2023-10-01",
  //   serviceDate: "2023-11-01",
  //   guestNo: 1,
  //   guestName: "Guest 1",
  //   treatmentName: "Shirobhyanga",
  //   startTime: "09:00:00",
  //   endTime: "10:00:00",
  //   period: 60,
  //   price: 1100,
  // },
];

const headerText = [
  "Member ID",
  "ID",
  "Booking No.",
  "Branch",	
  "Booking date",
  "Service date",
  "Guest No.",
  "Guest Name",
  "Treatment Name",
  "Start time",
  "End time",
  "Period (mins)",
  "Price (THB)",
  "Booking status",	
  "Payment status"
]

const headerMap = {
  memberId: "Member ID",
  id: "ID",
  bookingNo: "Booking No.",
  branchName:"Branch",	
  bookingDate: "Booking date",
  serviceDate: "Service date",
  guestNo: "Guest No.",
  guestName: "Guest Name",
  treatmentName: "Treatment Name",
  startTime: "Start time",
  endTime: "End time",
  period: "Period (mins)",
  price: "Price (THB)",
  bookingStatus: "Booking status",
  paymentStatus: "Payment status"
}

const TemplateExport = ({ startDate = "", endDate = "", branch = null, dateType = 0 }) => {
  const [templateData, setTemplateData] =  useState(mockRes)
  // templateData = mockRes

  const exportToExcel = () => {
    const fileName = `Booking-Detail_${moment().format('YYYYMMDD-HHmmss')}.xlsx`
    let rows = [
      // { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
      // { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
      // { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
      // { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
    ];
    const { header } = genHeader(startDate, endDate)
    const { headerTable, hTableWidth } = genHeaderTable(headerText)
    console.log("🚀 ~ file: TemplateExport.js:26 ~ exportToExcel ~ header ", header)
    rows.push(header)
    rows.push(headerTable)
    
    const { rowData } = resDataToRow(templateData)
    rows = [ ...rows, ...rowData]

    const ws = XLSX.utils.aoa_to_sheet(rows)
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 }, e: { r: 0, c: 2 } },
    //   { s: { r: 5, c: 2 }, e: { r: 5, c: 3 } },
    //   { s: { r: 8, c: 2 }, e: { r: 8, c: 3 } },
    ];
    ws["!merges"] = merge;
    ws['!cols'] = hTableWidth;

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Booking Detail");
    XLSX.writeFile(wb, fileName);
  };

  function resDataToRow(items = []) {
    let rowsAns = []
    for (let item0 of items) {
      console.log('item >>', item0)
      const rowDataTable = genDataTable(item0, headerMap, headerText)
      console.log("🚀 ~ file: TemplateExport.js:35 ~ exportToExcel ~ rowDataTable:", rowDataTable)
      rowsAns.push(rowDataTable)
    }
    return  { rowData: rowsAns }
  }

  function genHeader(startDate, endDate) {

    let rowStyle = { bgColor: 'C9DAF8', bold: true } // Style กลางของส่วนนี้

    let text0 = `Booking Detail (วันที่ ${moment(startDate).format('DD/MM/YYYY')} ถึง ${moment(endDate).format('DD/MM/YYYY')})`

    let ansRow = [ vCell(text0, { ...rowStyle } ), vCell("", { ...rowStyle } ), vCell("", { ...rowStyle } ) ]

    return { header: ansRow }
  }

  function genHeaderTable(headerText = []) {

    let rowStyle = { bgColor: 'C4C7E9', bold: true } // Style กลางของส่วนนี้

    let allH = headerText.map((item, i) => vCell(item, { ...rowStyle, alignment: 'middle' } ))
    let ansRow = [ ...allH ]
    let widthRow = { "1" : 20, "6" : 26 }
    let wAllRows = headerText.map((item, i) => {
      if (widthRow[i]) {
        return { width: widthRow[i] }
      }
      return { width: 14 }
    })
    let rowWidth = [ ...wAllRows ]

    return { headerTable: ansRow, hTableWidth: rowWidth  }
  }

  function genDataTable(objData = {}, headerMap = {}, headerTable = []) {
    let rowStyle = { } // Style กลางของส่วนนี้
    console.log('headerTable >>', headerTable)
    console.log('headerMap >>', headerMap)
    console.log('objData >>', objData)

    let ansRow = []
    const findKeyByValue = (obj = {}, value = "") => {
      for (let k in obj) {
        if (obj[k] === value) {
          return k
        }
      }
      return ""
    }

    for (const ht of headerTable) {
      let key = findKeyByValue(headerMap, ht)
      let data = objData[key]
      ansRow.push(vCell(data, { ...rowStyle } ))
    }

    return ansRow
  }

  function vCell (value, style = {}) {
    const tmpvalue = !!value || value === 0 ? value: ""
    let tmpCell = { v: tmpvalue , t: "s", s: { font: { name: "Sarabun", sz: 10 } } }
    if (style.bold) {
      tmpCell.s.font.bold = true // 'FFFF00' '000000'
    }
    if (style.bgColor) {
      tmpCell.s.fill = { fgColor: { rgb: style.bgColor } } // 'FFFF00' '000000'
    }
    if (style.alignment) {
      tmpCell.s.alignment = { horizontal: style.alignment } // 'left' 'center' 'right'
    }
    if (style.alignment === 'middle') {
      tmpCell.s.alignment = { horizontal: 'center', vertical: 'center', wrapText: true }
    }
    return tmpCell
  }



  const getExportBookingDetail = async (payload) => {
    try {
      const { data } = await ExportBookingDetail({ data: payload });
      if (data.resultCode === 20000) {
        setTemplateData(reMapRes(data.body))
        // setTimeout(() => exportToExcel(), 500)
      } 
    } catch (e) {
      console.log("ERR getExportBookingDetail >> ", e);
    }
  };

  const reMapRes = (body = []) => {
    console.log('body >>', body)
    const bookingStatusConfig = {
      "0": "Booked",
      "1": "Check-in",
      "2": "Expired",
      "3": "Payment Waiting",
      "4": "No show",
      "5": "Refund",
    }

    const paymentStatusConfig = {
      "0": "Success",
      "1": "Unsuccessful",
      "2": "Unsuccessful",
    }

    let ans = body.map(item => {
      return {
        ...item,

        bookingDate: item.bookingDate ? moment(item.bookingDate).format('DD/MM/YYYY') : "",
        serviceDate: item.serviceDate ? moment(item.serviceDate).format('DD/MM/YYYY') : "",
        branchName: item.branchName ? item.branchName : "",
        startTime: item.startTime ? helper.TIME.toShortTimeCom(item.startTime) : "",
        endTime: item.endTime ? helper.TIME.toShortTimeCom(item.endTime) : "",
        price: item.price ? item.price : "0",
        pointBurn: item.pointBurn ? item.pointBurn : "0",
        pointEarn: item.pointEarn ? item.pointEarn : "0",
        discount: item.discount ? item.discount : "0",
        grandTotal: item.grandTotal ? item.grandTotal : "0",
        reschedule: item.reschedule ? item.reschedule : "0",
        bookingStatus : item.bookingStatus || item.bookingStatus == 0 ? bookingStatusConfig[item.bookingStatus] : "",
        paymentStatus : item.paymentStatus || item.paymentStatus == 0  ? paymentStatusConfig[item.paymentStatus] : "",
        isSurveyAnswer: item.isSurveyAnswer ? "TRUE" : ""
      }
    })
    console.log('ans >>', ans)
    return ans
  }

  useEffect(() => {
    if (startDate && endDate) {
      console.log('templateData >>', templateData)
      setTimeout(() => exportToExcel(), 500)
    }
  }, [ templateData ])

  const onSubmit = () => {
    const payload = {
      dateType: dateType ? dateType : 0,
      startDate: moment(startDate).format('YYYY-MM-DDT00:00:00.000') + "Z",
      endDate: moment(endDate).format('YYYY-MM-DDT23:59:59.999') + "Z",
      branch: branch ? branch : undefined
    }
    console.log('payload >>', payload)
    getExportBookingDetail(payload)
  }

  return (
    <Button label="Export" onClick={()=> onSubmit()} disabled={!startDate || !endDate}/>
  );
};

export default TemplateExport;