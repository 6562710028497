import React, { useState } from "react";
// import ButtonPrimary from "../../components/button/ButtonPrimary";
import { SearchBookingDetails } from "../../services/Booking";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import helper from "../../services/helper";
import QRCode from "react-qr-code";

const bookingStateMap = {
  "0":"Success",
  "1":"Fail",
  "2":"Cancel",
}
export default function Print({ }) {
  const { bookingId } = useParams();
  const [ bookingDetail, setBookingDetail ] = useState({}) 
  const [ bookingList, setBookingList ] = useState([]) 
  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        setBookingDetail(resData)
        setBookingList(resData?.bookingList ?? [])
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };

  useEffect(() => {
    if (bookingId) {
      const payload = {
        bookingId: bookingId
      }
      getBookingDetails(payload)
    }
    document.body.style.background = '#CCCCCC'
  }, [bookingId] )

  return (
    <main className="relative">

      <button
        size="mini"
        className="print-btn0 btn-hl"
        onClick={() => {
          window.print()
        }}
      >
        Print
      </button>
      <div id="preview-sug" className="page no-padding">
        <PaddingA4>
          {/* {JSON.stringify(bookingDetail)} */}
          <h4 className="tTitleBooking">Booking Order : {bookingDetail.bookingNo}</h4>
          <table className="print-table">
            <tr>
              <td className="t">Name : </td>
              <td>{bookingDetail.contactName}</td>
              <td className="t">Email : </td>
              <td>{bookingDetail.contactEmail}</td>
            </tr>
            <tr>
              <td className="t">Phone : </td>
              <td>{bookingDetail.contactTel}</td>
              <td className="t">Service Date : </td>
              <td>{helper.TIME.toShortDateNormal(bookingDetail.bookingDate)}</td>
            </tr>
            
            <tr>
              <td className="t">Guest Amout : </td>
              <td>{bookingList.length}</td>
              <td className="t">Service Time : </td>
              <td>{helper.TIME.toShortTime(bookingDetail.bookingStart)}</td>
            </tr>
            <tr>
              <td className="t">Create at : </td>
              <td>{helper.TIME.toShortDateNormal(bookingDetail.createdOn)}</td>
              <td className="t">Branch : </td>
              <td>{bookingDetail.location}</td>
            </tr>
            <tr>
              <td className="t">Status Payment :</td>
              <td>{bookingDetail.paymentStatus  === null ? '' :bookingStateMap[bookingDetail.paymentStatus]}</td>
              <td className="t">Price (THB) : </td>
              <td>{helper.FN.toNumberWithCommas(bookingDetail.total)}</td>
            </tr>
          </table>
          <table className="print-table mt-[16px]" style={{ marginTop: '16px'}}>
            <tr>
              <td className="t" >Treatment Detail</td>
            </tr>
          </table>
          
          <div className="bMassageItem">
          {
              bookingList?.map((item,i) => {
                return (
                <>
                  <table className="print-table">
                    <tr>
                      <td className="t">Guest No : </td>
                      <td className="v">{item?.guestNumber ?? "-"}</td>
                    </tr>
                    <tr>
                      <td className="t">Name : </td>
                      <td className="v">{item?.guestName ?? "-"}</td>
                    </tr>
                    <tr>
                      <td className="t">Treatment 1 : </td>
                      <td className="v">{item?.subTreatment[0]?.name ?? "-"}</td>
                    </tr>
                    <tr>
                      <td className="t">Treatment 2  : </td>
                      <td className="v">{item?.subTreatment[1]?.name ?? "-"}</td>
                    </tr>
                  </table>
                  
                </>
             )
            })
          }
          </div>
      
         
        </PaddingA4>
        {
          bookingDetail?.bookingNo
          ? (
            <div className="print-qrcode">
              <QRCode
                className=""
                size={100}
                value={bookingDetail.bookingNo}
                viewBox={`0 0 100 100`}
              />
            </div>
          )
          : <></>
        }
        
        <div className="print-signature">
          <p className="customer-sig">Customer's Signature</p>
          <div className="box-sig"></div>
          <p className="date-sig">Date: ____________________</p>
        </div>
      </div>
    </main>
  );
}

const InfoValue = ({ title = "", value = "" }) => {
  return (
    <div className="flex gap-[8px]">
      <h5 className="font-medium">{title}</h5>
      <p>{value}</p>
    </div>
  )
}

const PaddingA4 = (props) => {
  return (
    <>
      <div style={{ padding: "1cm 1cm", position: 'relative' }}>{props.children}</div>
    </>
  );
};
