import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import "./assets/styles/styles.css";
import "./assets/styles/import-slot.css";
import "./assets/styles/export.css";
import "./assets/styles/paper.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import App from "./App";
import { PrimeReactProvider } from 'primereact/api';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConfigProvider theme={{ token: { colorPrimary: "#DDB05B" } }}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </ConfigProvider>
  </BrowserRouter>
);
