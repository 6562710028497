import { get } from "./Api";

export function SearchDdlMobileCode() {
  return get({ url: "/api/v1/healthland/searchDdlMobileCode" });
}
export function SearchDdlNationality() {
  return get({ url: "/api/v1/healthland/searchDdlNationality" });
}
export function SearchDdlBranch() {
  return get({ url: '/api/v1/healthland/ddl/searchDdlBranch' })
}