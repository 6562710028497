import React, { useState, useEffect } from "react";
import { Calendar  } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import TemplateExport from "./TemplateExport";
import { SearchDdlBranch } from "../../services/Dropdown";
import { ExportBookingSummary } from "../../services/Export";
import helper from "services/helper";

const DEF_DD_BRANCH = [{ id: 0, name: 'All' }]
const DD_DATE_TYPE = [{ id: 0, name: 'Booking date' }, { id: 1, name: 'Service date' }]

export default function BookingSummary() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ddBranch, setDDBranch] =  useState([])
  const [branch, setBranch] =  useState([])
  const [dateType, setDateType] =  useState(0)

  const getDropdownBranch = async (payload) => {
    try {
      const { data } = await SearchDdlBranch({ data: payload });
      if (data.resultCode === 20000) {
        const tmpData = [ ...DEF_DD_BRANCH, ...data.body]
        setDDBranch(tmpData)
      } 
    } catch (e) {
      console.log("ERR getDropdownBranch >> ", e);
    }
  };

  useEffect(() => {
    getDropdownBranch()
  }, [])
 
  return (
    <div className="export-layout">
      {/* <div className="L">
        {
          dateType == 0 
          ? <p>Booking Date</p>
          : <p>Service Date</p>
        }
      </div> */}
      <div className="R">
        <div className="form">
          <div>
            <p>Date type</p>
            <Dropdown
              className="export-dropdown"
              value={dateType} 
              onChange={(e) => setDateType(e.value)} 
              options={DD_DATE_TYPE} 
              optionLabel="name" 
              optionValue="id"
              placeholder="Select Branch" 
            />
          </div>
          <div>
            <p>Branch</p>
            <Dropdown
              className="export-dropdown"
              value={branch} 
              onChange={(e) => setBranch(e.value)} 
              options={ddBranch} 
              optionLabel="name" 
              optionValue="id"
              placeholder="Select Branch" 
            />
          </div>
          <div>
            <p>Start date</p>
            <Calendar
              className="export-calendar"
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              showIcon
              severity="success"
              readOnlyInput
              maxDate={endDate}
            />
          </div>
          <div>
            <p>End date</p>
            <Calendar
              className="export-calendar"
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              minDate={startDate}
              readOnlyInput
              showIcon
            />
          </div>
        </div>
        <div className="submit">
          <TemplateExport startDate={startDate} endDate={endDate} branch={branch} dateType={dateType}/>
        </div>
      </div>
    </div>
  );
}
