import axios from "axios";
import helper from "./helper";
import AppConfig from "../utils/config";
const CONFIG = AppConfig.config()

console.log('process.env >>', process.env)
// export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://axelor-dev-haier-nodered-befa48.scapp.work'
export const baseUrl = CONFIG.BASE_URL

export const headers = {
    Accept: 'application/form-data',
    'Content-Type': 'application/json',
};

// axios.interceptors.request.use(function (config) {
//     const tranId = Logger.getTranID()
//     config.tranId = tranId
//     Logger.info(`${config.method} REQ: ${tranId} => ${config.url}`, { params: config.params, payload: config.data })
//     return config;
//   }, function (error) {
//     Logger.error(`ERROR Request`, { error: `Error ${error}` })
//     return Promise.reject(error);
//   });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     const { config, data } = response
//     Logger.info(`${config.method} RES: ${config.tranId} => ${config.url}`, { params: config.params, payload: config.data, data: data })
//     return response;
//   }, function (error) {
//     Logger.error(`ERROR Response`, { error: `Error ${error}` })
//     return Promise.reject(error);
//   });

export function get({ url, params }) {
    return axios({
        url,
        method: 'get',
        baseURL: baseUrl,
        headers: { ...headers, 'authorization': helper.Auth.getToken(), 'lang': helper.Auth.getLang() },
        params,
        timeout: 20000
    }).then(res => {
        // console.log('GET api main success >>', res)
        return res
    }).catch((e) => {
        const resData = e?.response?.data ?? {}
        console.log('GET api main err >>', e.response.data)
        return e
    })
}

export function post({ url, params, data }) {
    return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: { ...headers, 'authorization': helper.Auth.getToken(), 'lang': helper.Auth.getLang() },
        params,
        data,
        timeout: 20000
    }).then(res => {
        // console.log('POST api main success >>', res)
        return res
    }).catch((e) => {
        const resData = e?.response?.data ?? {}
        console.log('POST api main err >>', e.response.data)
        return e
    })
}