import { Route, Routes } from "react-router-dom";
import Home from "pages/Home";
import ImportSlot from "pages/ImportSlot";
import BookingSummary from "pages/ExportBookingSummary/BookingSummary";
import Layouts from "layout/Layout";
import BookingDetail from "pages/ExportBookingDetail/BookingDetail";
import Print from "pages/BookingPrint/Print";

function App() {
  return (
    <>
      <Layouts>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/import-slot/:branchId" element={<ImportSlot />} />
          <Route path="/export-booking-summary" element={<BookingSummary />} />
          <Route path="/export-booking-detail" element={<BookingDetail/>} />
          <Route path="/print/:bookingId" element={<Print/>} />
        </Routes>
      </Layouts>
    </>
  );
}

export default App;
