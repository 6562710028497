import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, InputNumber,DatePicker } from "antd";
import { Tag } from 'antd';
import { SearchSubTreatmentByBranch, CreateImportSlot } from "../../services/ImportSlot";
import { SearchDdlBranch } from "../../services/Dropdown";
import moment from "moment";
import { useParams } from "react-router-dom";
import _, { values } from "lodash"
import helper from "services/helper";
import { Calendar } from "primereact/calendar";

const { Option } = Select;
const { RangePicker } = DatePicker


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const OPTIONS = ["Apples", "Nails", "Bananas", "Helicopters"];
const formItemLayout =  { labelCol: { span: 24 }, wrapperCol: { span: 24 } } 

let curValidate = ""

const App = () => {
  const { branchId } = useParams()
  const formRef = React.useRef(null);


  const [ branchNo, setBranchNo ] = useState(null)

  const [ subTreatment, setSubTreatment ] = useState([])

  const [ morning, setMorning ] = useState([])
  const [ afternoon, setAfternoon ] = useState([])
  const [ evening, setEvening ] = useState([])

  const [ errorTime, setErrorTime ] = useState([])
  
  const [ rangeDate, setRangeDate ] = useState([]);
  const [ errorDate, setErrorDate ] = useState([])

  const [ isSubmit, setIsSubmit ] = useState(false)

  const onFinish = (values) => {
    console.log(values);
    const { morning, afternoon, evening } = values
    
    const time = [ ...morning, ...afternoon, ...evening]
    const subTreatment = values.treatment
    const qty = values.qty

    console.log('rangeDate >>>', rangeDate)
    if (rangeDate.length === 2) {
      setIsSubmit(false)
      const _startDate = rangeDate[0]
      let _endDate = rangeDate[1]
      if (rangeDate.includes(null)) {
        _endDate = rangeDate[0]
      }

      const startDate = helper.TIME.toDateAdd7(new Date(_startDate).toISOString())
      const endDate = helper.TIME.toDateAdd7(new Date(_endDate).toISOString())
      console.log("🚀 ~ file: index.js:60 ~ onFinish ~ startDate:", startDate)
      console.log("🚀 ~ file: index.js:62 ~ onFinish ~ endDate:", endDate)

      const newPayload = convertPayload(branchNo, [subTreatment], startDate, endDate, time, qty)
      console.log('newPayload >>', newPayload)

      const newPayload2 = removeErrorDate(newPayload, errorDate)
      console.log('newPayload2 >>', newPayload2)
      postImportSlot(newPayload)
    } else {
      alert("กรุณาเลือกช่วงเวลา")
      setIsSubmit(true)
    }
  };

  const removeErrorDate = (payload = [], errorDate = []) => {
    console.log('payload >>', payload)
    console.log('errorDate >>', errorDate)
    const _errorDate = errorDate.map(item => helper.TIME.toDateAdd7(new Date(item).toISOString()))
    console.log('_errorDate >>', _errorDate)
    const ans = payload.map(item0 => {
      return {
        ...item0,
        subTreatments: item0.subTreatments.map(item1 => {
          return {
            ...item1,
            detail: item1.detail.filter(item2 => !_errorDate.includes(item2.date))
          }
        })
      }
    })
    console.log('ans >>', ans)
    return ans
  }


  const onReset = () => {
    formRef.current?.resetFields();
    onDefaultField()
    setErrorDate([])
    setErrorTime([])
    setRangeDate([])
    setIsSubmit(false)
  };
  const onFill = () => {
    formRef.current?.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };

  const onDefaultField = () => {
    let openTime = "09:00:00"
    let endTime = "21:30:00"
    let period = 30
    const { morning, afternoon, evening } = calTimeOption(openTime, endTime, period)
    setMorning(morning)
    setAfternoon(afternoon)
    setEvening(evening)

    formRef.current?.setFieldsValue({
      morning: morning,
      afternoon: afternoon,
      evening: evening
    });
  }

  function calTimeOption(openTime, endTime, period) {
    let curTime = moment(openTime, "HH:mm:ss")
    const firstTime = curTime.format("HH:mm")
    let morning = [firstTime]
    let afternoon = []
    let evening = []
    while (curTime.format("HH:mm:ss") < endTime) {
      curTime.add(period, 'minutes');
      // console.log(curTime.format("HH:mm"));
      const tmpCurTime = curTime.format("HH:mm")

      const [hour, minute] = tmpCurTime.split(':')
      if (+hour >= 0 && +minute >= 0 && +hour <= 11 && +minute <= 59) {
        morning.push(tmpCurTime)
      } else if (+hour >= 12 && +minute >= 0 && +hour <= 17 && +minute <= 59) {
        afternoon.push(tmpCurTime)
      } else if (+hour >= 18 && +minute >= 0 && +hour <= 23 && +minute <= 59) {
        evening.push(tmpCurTime)
      }
    }

    evening.pop()

    return { morning, afternoon, evening }
  } 

  const getSubTreatmentByBranch = async (branchId = 0) => {
    try {
      const { data } = await SearchSubTreatmentByBranch({
        params: { branchId: branchId },
      });
      if (data.resultCode === 20000) {
        console.log("data.body >>", data.body);
        setSubTreatment(data.body)
      }
    } catch (e) {
      console.log("ERR getSubTreatmentByBranch", e);
    }
  };
  
  const getBranch = async () => {
    try {
      const { data } = await SearchDdlBranch();
        if (data.resultCode === 20000) {
          console.log("data.body >>", data.body);
          const branch = data.body.find(item => item.id == branchId)
          setBranchNo(branch.branchNo)

        }
    } catch (e) {
      console.log("ERR getBranch", e);
    }
  };

  useEffect(() => {
    getBranch()
    getSubTreatmentByBranch(branchId)
    onDefaultField()
  }, [])


  function genErrorTime(dataError = []) {
    let ans = dataError.map(item => {
      console.log('item.startTime >>', item.startTime)
      const [ hour, minute ] = `${item.startTime}`.split(":")
      return `${hour}:${minute}`
    })
    console.log('ans >>', ans)
    return ans
  }

  function genErrorDate(dataError = []) {
    let ans = dataError.map(item => {
      console.log('item dateAt >>', item.dateAt)
      // const [ hour, minute ] = `${item.startTime}`.split(":")
      // return `${hour}:${minute}`
      return moment(item.dateAt, 'DD-MM-YYYY').format('YYYY-MM-DD')
    })
    const ans2 = [...new Set([...ans])];
    console.log('ans2 >>', ans2)
    const ans3 = ans2.map(item => new Date(item))
    console.log('ans3 >>', ans3)
    return ans3
  }


  const postImportSlot = async (payload) => {
    try {
      const { data } = await CreateImportSlot({ data: payload });
      console.log('data.body >>', data)
      if (data.resultCode === 20000) {
          const resData = data.body;
          console.log('resData >>', resData)
          alert("Import Success")
          setErrorDate([])
          setErrorTime([])
        } else if (data.resultCode === 40401){
          alert("40401 Sub Treatment Not Found")
          console.log('data.body >>', data.body)
          setErrorTime(genErrorTime(data.body[0].detail))
          setErrorDate(genErrorDate(data.body[0].detail))
        } else if (data.resultCode === 40301) {
          alert("40301 Already Exist")
          setErrorTime(genErrorTime(data.body[0].detail))
          setErrorDate(genErrorDate(data.body[0].detail))
      }
    } catch (e) {
      console.log(`🦄: postImportSlot -> e`, e);
    }
  };

  const customValidator = (rule, value, callback) => {
    curValidate = rule.field
    const morning = formRef.current.getFieldValue('morning');
    const afternoon = formRef.current.getFieldValue('afternoon');
    const evening = formRef.current.getFieldValue('evening');
    console.log('rule >>', rule)
    console.log('value >>', value)
    console.log('morning >>', morning)
    console.log('afternoon >>', afternoon)
    console.log('evening >>', evening)


    // Your custom validation logic goes here
    const countTime = morning.length + afternoon.length + evening.length
    console.log('countTime >>', countTime)
    
    if (countTime === 0 && isSubmit) {
      let textErr = "Please select Time"
      setFieldError("morning", textErr)
      setFieldError("afternoon", textErr)
      setFieldError("evening", textErr)
      callback(textErr);
    } else {
      clearFieldError("morning")
      clearFieldError("afternoon")
      clearFieldError("evening")
      callback();
    }

    let checkField = ["morning", "afternoon", "evening"]
    checkField = checkField.filter(item => item !== curValidate)
    console.log('checkField >>', checkField)



  };

  const setFieldError = (fieldName, error) => {
    formRef.current.setFields([
      {
        name: fieldName,
        errors: [error],
      },
    ]);
  };

  const clearFieldError = (fieldName) => {
    formRef.current.setFields([
      {
        name: fieldName,
        errors: [],
      },
    ]);
  };

  const validateAllFields = () => {
    formRef.current
      .validateFields()
      .then(() => {
        // Validation passed, continue with your logic here
        onFinish(formRef.current.getFieldsValue());
      })
      .catch((errorInfo) => {
        // Validation failed, you can handle the error information here
        console.log('Validation failed:', errorInfo);
      });
  };
  
  return (
    <div className="layout">
      <Form
        {...formItemLayout}
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        onSubmitCapture={()=> {
          setIsSubmit(true)
          console.log('onSubmitCapture !!')
        }}
        layout={"vertical"}
        validateTrigger="onChange"
        // style={{ maxWidth: 600 }}
      >
        <div className="import-slot-layout">
          {/* {rangeDate.length} */}
          {/* {JSON.stringify(errorDate)} */}
          <div className="L">
            <Calendar
              className={`import-calendar ${ isSubmit && rangeDate.length === 0 ? "error" : ""}`}
              value={rangeDate}
              onChange={(e) => setRangeDate(e.value)}
              selectionMode="range"
              inline
              disabledDates={errorDate}
            />
          </div>
          <div className="R">
            <Form.Item
              name="treatment"
              label="Treatment"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select Treatment"
                style={{ width: "100%" }}
                options={subTreatment.map((item) => ({
                  value: item.subTreatmentNo,
                  label: item.subTreatmentName,
                }))}
              />
            </Form.Item>
            
            <Form.Item
              name="morning"
              label="Morning"
              rules={[
                {
                  validator: customValidator, // Use the custom validation function
                },
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Morning"
                style={{ width: "100%" }}
                tagRender={(p) => tagRenderError(p, errorTime)}
                options={morning.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="afternoon"
              label="Afternoon"
              rules={[
                {
                  validator: customValidator, // Use the custom validation function
                },
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Afternoon"
                style={{ width: "100%" }}
                tagRender={(p) => tagRenderError(p, errorTime)}
                options={afternoon.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="evening"
              label="Evening"
              rules={[
                {
                  validator: customValidator, // Use the custom validation function
                },
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Evening"
                style={{ width: "100%" }}
                tagRender={(p) => tagRenderError(p, errorTime)}
                options={evening.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="qty"
              label="quantity"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="quantity"
                size="large"
                min={0}
              />
            </Form.Item>
            <br/>

            <Form.Item>
              <div className="action-form">
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
                <Button htmlType="button" onClick={onReset} size="large">
                  Reset
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
    
  );
};
export default App;


const tagRenderError = (props, error = []) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={error.includes(value) ? "error" : "default" }
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      // style={{
      //   marginRight: 3,
      // }}
    >
      {label}
    </Tag>
  );
};


function convertPayload(branchNo = null, subTreatments = [], startDate = null, endDate = null, times = [], qty = 0) {
  let mainAns = {
    branchNo: branchNo,
    subTreatments: []
  };

  let ansSubTreatments = []

  for(let st of subTreatments) {
    let tmpSubTreatment = {
      subTreatmentNo: st,
      detail: []
    }
    for (const t of times) {
      const dateArray = generateDateArray(startDate, endDate)
      for (let dateAt of dateArray) {
        const tmpData = {
          date: dateAt,
          startTime: t,
          amount: qty
        }
        tmpSubTreatment.detail.push(tmpData)
      }
    }
    ansSubTreatments.push(tmpSubTreatment)
  }

  mainAns.subTreatments = ansSubTreatments

  return [ mainAns ]
}

function generateDateArray(startDate, endDate) {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const dateArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const tmp = new Date(currentDate).toISOString().split("T")[0]
    dateArray.push(tmp);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}